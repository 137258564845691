import React, {useContext, useEffect, useState, useRef} from 'react';

import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import {transition1} from '../transitions'
//import { CursorContext } from '../context/CursorContext';
import Picture from './Picture'
import { usePictures } from '../context/PictureContext'
// import { urlFor } from '../imageUrl';

const Portfolio = ({setHeaderVisible, headerVisible}) => {
    
  //const {mouseEnterHandler,mouseLeaveHandler} = useContext(CursorContext)

  const lastScroll = useRef(0)
  const scrollUpThreshold = 50
  const scrollUpDistance = useRef(0)
  const pictures = usePictures()


  useEffect(() => {

    const portfolioDiv = document.getElementById('portfolio');

    const handleScroll = () => {
      const currentScroll = portfolioDiv.scrollTop
      const maxScroll = portfolioDiv.scrollHeight - portfolioDiv.clientHeight;

      if (currentScroll <= maxScroll) {
        if (currentScroll <= 0) {
          setHeaderVisible(true)
          scrollUpDistance.current = 0 //reset scroll up distance
        }else if(currentScroll >lastScroll.current){
          setHeaderVisible(false)
          scrollUpDistance.current = 0 //reset scroll up distance
        }else if (currentScroll < lastScroll.current){
          scrollUpDistance.current += lastScroll.current - currentScroll
          if (scrollUpDistance.current >= scrollUpThreshold) {
          setHeaderVisible(true)
          }
        }
      }
      lastScroll.current = currentScroll
    }

    portfolioDiv.addEventListener('scroll', handleScroll)
    return () => {
      portfolioDiv.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <section className ='section overflow-auto'>
      <div className = {`flex flex-col lg:flex-row h-full items-center justify-start gap-x-16 text-center lg:text-left ${headerVisible ? 'pt-32 transition-top duration-700 ease-out' : 'pt-4 transition-top duration-700 ease-out'} pb-0 lg:pb-36`} >
        <motion.div 
        // onMouseEnter = {mouseEnterHandler}
        // onMouseLeave = {mouseLeaveHandler}
        initial = {{opacity:0, y:'-80%'}} 
        animate = {{opacity:1, y:0}}
        exit = {{opacity:0, y:'-80%'}}
        transition = {transition1}
        className = 'lg:w-1/3  sticky lg:pl-8 '>
          <h1 className = 'h1 text-center mb-4'>Portfolio</h1>
          <Link to = {'/contact'} className = 'btn lg:rounded-lg mb-[10px] mx-auto lg:mx-0'> 
            Schedule a shoot
          </Link>
        </motion.div>
        <div 
        // ref = {scrollRef}
        id = 'portfolio'
        className = 'lg:w-2/3 h-screen overflow-y-scroll p-8 pt-0 lg:pt-48'>
          {/*         <div className = 'w-2/3 h-screen  p-4 grid grid-cols-2 lg:gap-2 mt-10 '>
          <div className = 'items-center'> */}
          <div className = 'flex flex-wrap -mx-2 pb-24'>
            <div className = 'w-1/2 '>
              {pictures
              .sort((a,b)=>a.id - b.id)
              .filter((_,index)=>index %2 === 0)
              .map((item)=>{
                return( 
                  <div key={`item-${item.id}`} className='p-2'>
                      <Picture item = {item} source='portfolio'/>
                    </div>
                )
              })}
            </div>
            <div className = 'w-1/2'>
              {pictures
                .sort((a,b)=>a.id - b.id)
                .filter((_,index)=>index %2 !== 0)
                .map((item)=>{
                  return( 
                    <div key={`item-${item.id}`} className='p-2'>
                      <Picture item = {item} source='portfolio'/>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      {/* <div className=''>

      </div> */}
    </section>
  )
};

export default Portfolio;
