import React, {useState} from 'react';
import {IoMdClose} from 'react-icons/io'
import {CgMenuRight} from 'react-icons/cg'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import {
  ImFacebook,
  ImPinterest,
  ImInstagram,
} from 'react-icons/im'

const menuVariants = {
  hidden: {
    x: '100%'
  },
  show:{
    x: 0,
    transition : {
      ease: [0.6,0.01,-0.05,0.9]
    }
  }
}

const MobileNav = () => {
  const [openMenu, setOpenMenu] = useState(false)
  return <nav className = 'text-slate-200  text-opacity-90 xl:hidden'>
    <div 
    onClick = {()=>setOpenMenu(true)} 
    className = 'text-3xl cursor-pointer'>
      <CgMenuRight />
    </div>
      <motion.div 
      variants = {menuVariants} 
      initial = 'hidden'
      animate = {openMenu ? 'show' : ''}
      className = 'bg-white shadow-2xl shadow-black w-full absolute top-0 right-0 max-w-xs h-screen z-20'>
      <div 
      onClick = {()=> setOpenMenu(false)}
      className = 'text-4xl absolute z-30 left-4 top-14 text-primary cursor-pointer'>  
        <IoMdClose />
      </div>
      <ul className = 'h-full flex flex-col justify-center items-center gapy-y-8 text-primary font-primary font-bold text-3xl'>
        <li onClick = {()=> setOpenMenu(false)}>
          <Link to = '/'>Home</Link>
        </li>
        <li onClick = {()=> setOpenMenu(false)}>
          <Link to = '/about'>About</Link>
        </li>
        <li onClick = {()=> setOpenMenu(false)}>
          <Link to = '/portfolio'>Portfolio</Link>
        </li>
        <li onClick = {()=> setOpenMenu(false)}>
          <Link to = '/services'>Services</Link>
        </li>
        <li onClick = {()=> setOpenMenu(false)}>
          <Link to = '/contact'>Contact</Link>
        </li>
        <li className = 'flex gap-x-6 items-center justify-center pt-4 text-2xl'>
          <li onClick = {()=> setOpenMenu(false)}>
            <a href = 'https://www.facebook.com/profile.php?id=61559648666254'
            target = '_blank'
            rel='noreferrer'
          >
              <ImFacebook/>
            </a>
          </li>
          <li onClick = {()=> setOpenMenu(false)}>
            <a href = 'https://www.instagram.com/aperturevis/'
            target = '_blank'
            rel='noreferrer'
            >
              <ImInstagram/>
            </a>
          </li>      
        </li>
      </ul>
    </motion.div>
  </nav>
};

export default MobileNav;
